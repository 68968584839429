import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetApi, PostApi } from '../services/CommonService2.js';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerTwo.js";
function FooterTwo() {

    const [socialLinks, setSocialLinks] = useState([]);
    const [settings, setSettings] = useState([]);
    const [logo, setLogo] = useState([]);
    const [tenant, setTenant] = useState(false)
    const [tntId, setTntId] = useState('');
    const [address, setAddress] = useState([]);
    const [tenantData, getTenantData] = useState([])

    useEffect(() => {
        loadData()
        getLogo()
        getTenant()
        setTntId(JSON.parse(localStorage.getItem('tID')))
        getFooter()



    }, [])
    const getTenant = async () => {
        const reqparam = {
            pageno: '-1',
            query: {}
        }
        const tenantresp = await PostApi(reqparam, 'TENANT');
        console.log("footer tenantresp", tenantresp);
        // console.log("footerrrr ",tenantresp?.data[0].tenantid);
        getTenantData(tenantresp?.data[0])

        if (tenantresp?.data[0].tenantid == "526daf25") {
            setTenant(true)

        }

    }


    const loadData = async () => {
        const reqparams = {
            pageno: 1,
            query: {}

        }
        const respData = await PostApi(reqparams, 'SOCIALLINK');
        const respData2 = await PostApi(reqparams, 'SETTING');
        setSocialLinks(respData.data.docs);
        setSettings(respData2.data.docs.filter((ele) => { return ele.actiontype != 'About Constituency' && ele.actiontype != 'About Leader' }))
        // console.log("settings footer!!!!!!!!!!!!!!!!!!!!!!!!",respData2.data);
    }


    const getLogo = async () => {
        const action = "INTRO"
        const reqparam = {
            pageno: '-1',
            query: { status: 'Active' }
        }

        const ResponseData = await GetApi(reqparam, action);
        setLogo(ResponseData?.data);

    }
    const getFooter = async () => {
        const reqparam = {
            pageno: '-1',
            query: {},
        }
        const tenantresp = await PostApi(reqparam, 'TENANT');
        // console.log("footer resp===", tenantresp.data[0].tenantotherinfo);
        setAddress(tenantresp?.data[0]?.tenantotherinfo)

    }
    return (
        // <Styles>
        <Styles style={{marginBottom:"-100%"}}>
            {/* Footer Two */}
            <footer className="footer2" >
                <Container>
                    {/* { console.log("tenantttttttt",tenant)} */}
                    <Row>
                        <Col sm="12" md="6" lg="4" style={{textAlign:'left'}}>


                            <div className="footer-logo-info footer1 mb-3 text-center">
                                {/* <img src={logo} alt="" className="img-fluid" /> */}
                                <Link to={"/"}><img src={`${logo[0] && logo[0].smallicon}`} alt="" className="img-fluid" /></Link>
                            </div>
                            <div className="copytext-area-1 text-left">
                                {socialLinks.length > 0 && <h5> Find us on, </h5>}
                                {socialLinks.map((item, inx) => {
                                    return (

                                        <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href={item.fblink}><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="list-inline-item"><a href={item.twitterlink}><i class="fa-brands fa-x-twitter" style={{ color: "black" }}></i></a></li>
                                            <li className="list-inline-item"><a href={item.linkedinlink}><i className="fab fa-linkedin-in"></i></a></li>
                                            <li className="list-inline-item"><a href={item.youtubelink}><i className="fab fa-youtube"></i></a></li>
                                            <li className="list-inline-item"><a href={item.instalink}><i className="fab fa-instagram"></i></a></li>
                                        </ul>
                                    )
                                })}
                            </div>
                        </Col>


                        {/* { <Col sm="12" md="6" lg="3" style={{textAlign:'center'}}>
                            <h5 className='text-light mb-2'>Android Downloads</h5>
                            <a href="https://play.google.com/store/apps/details?id=com.vptedu.io"><img src={google} style={{ width: "65%", textAlign: "center" }} /></a>
                          {tenantData?.androidlink ? <><div>
                            <Button className='p-2 mb-4' ><a href={ tenantData.androidlink} className='text-light'>Click Here to Get Android App</a></Button>
                            </div>
                                <div>
                                    <p className='text-light mb-2'>Scan the below QR to get Android App</p>
                                <img src={ tenantData.androidqr }  width="150" className='mb-5'/>
                                </div></>: <div style={{fontSize:'20px', margin:'20px 0px'}}> Updating Soon. . .</div>}
                            
                        </Col>}
                        { <Col sm="12" md="6" lg="3" style={{textAlign:'center'}}>
                            <h5 className='text-light mb-2'>IOS Downloads</h5>
                            <a href="https://play.google.com/store/apps/details?id=com.vptedu.io"><img src={google} style={{ width: "65%", textAlign: "center" }} /></a>
                          {tenantData?.ioslink ? <><div>
                            <Button className='p-2 mb-4' ><a href={ tenantData.ioslink } className='text-light'>Click Here to Get IOS App</a></Button>
                            </div>
                                <div>
                                    <p className='text-light mb-2'>Scan the below QR to get IOS App</p>
                                <img src={ tenantData.iosqr }  width="150" className='mb-5'/>
                                </div></> :<div style={{fontSize:'20px', margin:'20px 0px'}}> Updating Soon. . .</div>}
                            
                        </Col>} */}




                        <Col sm="12" md="6" lg="4" style={{textAlign:'left'}}>
                            <div className="f-links">
                                {settings && <h5>Quick Links</h5>}
                                <ul className="list-unstyled">
                                    {settings?.map((item, index) => (
                                        <li key={index}>
                                            <> <Link to={{ pathname: `/Settings/${item.actiontype}`, state: item }} className="custom-link">
                                                <h6 className="las la-angle-right text-light">{item.actiontype.charAt(0).toUpperCase() + item.actiontype.slice(1)}</h6>
                                            </Link><br /></>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>

                        <Col sm="12" md="6" lg="4" style={{textAlign:'left'}}>
                            <div className="f-links">
                                <h5>Contact Us</h5>

                                <ul className="list-unstyled1" type="none">
                                    <li><i className="las la-map-marker"></i>{address?.contactaddress}</li>
                                    <li><i className="las la-envelope"></i>{address?.supportemail}</li>
                                    <li><i className="las la-phone"></i>{address?.mobilenumber}</li>

                                </ul>



                                {/* {tntId == "526daf25" && <ul className="list-unstyled1" type="none">
                                    <li><i className="las la-map-marker"></i>T-Hub 2.0,Knowledge City,Serilingampally,Hyderabad,Telangana-500081</li>
                                    <li><i className="las la-envelope"></i>support@visualpathedtech.com</li>
                                    <li><i className="las la-phone"></i>+91 9030222849</li>

                                </ul>} */}
                                {/* {tntId == '7d94b6c9' && <ul className="list-unstyled1" type="none">
                                    <li><i className="las la-map-marker"></i>GANDHI BHAVAN,# 170,NH65,
                                        BHAGYA NAGAR SOCIETY COLONY,
                                        NAMPALLY - HYDERABAD</li>
                                    <li><i className="las la-envelope"></i>support@tcongressyuva.com</li>
                                    <li><i className="las la-phone"></i>+91 9030222849</li>

                                </ul>}
                                {tntId == '080916ea' && <ul className="list-unstyled1" type="none">
                                    <li><i className="las la-map-marker"></i>NTR Bhavan, Road No.2,
                                        Banjara Hills, Hyderabad, Telangana</li>
                                    <li><i className="las la-envelope"></i>support@tdpyuva.com</li>
                                    <li><i className="las la-phone"></i>+91 9030222849</li>

                                </ul>}
                                {tntId == '68c5eb9c' && <ul className="list-unstyled1" type="none">
                                    <li><i className="las la-map-marker"></i>Plot No.12, Rd Number 72,
                                        Prashasan Nagar, Jubilee Hills,
                                        Hyderabad, Telangana 500033</li>
                                    <li><i className="las la-envelope"></i>support@jspyuva.com</li>
                                    <li><i className="las la-phone"></i>+91 9030222849</li>

                                </ul>}
                                {tntId == 'e3bc6ff0' && <ul className="list-unstyled1" type="none">
                                    <li><i className="las la-map-marker"></i>GANDHI BHAVAN,# 170,NH65,
                                        BHAGYA NAGAR SOCIETY COLONY,
                                        NAMPALLY - HYDERABAD</li>
                                    <li><i className="las la-envelope"></i>support@khammamyuva.com</li>
                                    <li><i className="las la-phone"></i>+91 9030222849</li>

                                </ul>} */}

                            </div>
                        </Col>
                    </Row>

                    <Row>


                        <Col md="12">
                            <div className="copytext-area text-center">
                                <p>{address?.copyrights}</p>
                                {/* <p>Copyright &copy; 2017 | Designed With <i className="las la-heart"></i> by <a href={process.env.PUBLIC_URL + "/"} target="_blank" rel="noopener noreferrer">Visual Path</a></p> */}

                                {/* {tntId == "526daf25" && <p>© 2023. VisualpathEdTech. All Rights Reserved.</p>}
                                {tntId == '7d94b6c9' && <p>© 2023. Tcongressyuva. All Rights Reserved.</p>}
                                {tntId == '080916ea' && <p>© 2023. Tdpyuva. All Rights Reserved.</p>}
                                {tntId == '2d29d724' && <p>© 2023. Brseduhub. All Rights Reserved.</p>}
                                {tntId == 'e3bc6ff0' && <p>© 2023. Khammam. All Rights Reserved.</p>}
                                {tntId == '68c5eb9c' && <p>© 2023. JSP Yuva. All Rights Reserved.</p>} */}
                            </div>
                        </Col>
                    </Row>
                </Container>


                <BackToTop />
            </footer> 

        </Styles>
    );
}

export default FooterTwo
